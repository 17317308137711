import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../_services/user.service';
import { UrlResolverService } from '../_services/urlResolver.service';
import { AppComponent } from '../app.component';

@Injectable({ providedIn: 'root' })
export class EstudiosService {
  userData;

  private clinicalHistorySubject: BehaviorSubject<any>;
  public clinicalHistory: Observable<any>;
  public horusRestfullUrl: string;
  private sucursal;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private urlResolver: UrlResolverService
  ) {
    this.getUrl();
    this.sucursal = AppComponent.sucursal;
  }
  getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  getUrl() {
    this.urlResolver.getHorusRestfullUrl().subscribe((x) => {
      this.horusRestfullUrl = x.mensaje;
    });
    // this.horusRestfullUrl = 'http://localhost/horus_restfull';
  }

  getClinicalHistory(userData) {
    // console.log(this.sucursal)
    this.getUrl();
    return this.http.post<any>(
      this.horusRestfullUrl +
        '/api/episodioPaciente/historialPacientePorSucursalToken/',
      {
        numeroDocumento: userData.nroDocumento,
        tipoDocumento: userData.tipoDocumento,
        usuario: userData.usuario,
        sucursal: this.sucursal,
      }
    );
  }

  getHistorialClinicoExterno(userData, horusRestfullUrl) {
    
    return this.http.post<any>(
      horusRestfullUrl + '/api/episodioPaciente/historialPacientePorSucursal/',
      {
        numeroDocumento: userData.nroDocumento,
        tipoDocumento: userData.tipoDocumento,
        usuario: userData.usuario,
        sucursal: this.sucursal,
      }
    );
  }

  getInfoImagenesEstudio(estudioId) {
    return this.http.get<any>(
      this.horusRestfullUrl +
        '/api/historiaClinica/pedidos/restricted/chequeoEstadoImagenes/' +
        estudioId
    );
  }

  // getChequeoImagenes(nroPedido) {
  //   return this.http.get<any>(
  //     this.horusRestfullUrl +
  //       '/api/historiaClinica/pedidos/restricted/chequeoEstadoImagenes/' +
  //       nroPedido
  //   );
  // }

  getPedidoEstudio(nroPedido) {
    return this.http.post<any>(
      this.horusRestfullUrl +
        '/api/historiaClinica/pedidos/getPedidosGenericoPac',
      {
        numero: nroPedido,
      }
    );
  }

  getPedidosPaciente(nroDniPaciente, tipoDniPaciente, tipoPedi) {
    return this.http.post<any>(
      this.horusRestfullUrl + '/api/historiaClinica/pedidos/getPedidosGenericoPac',
      { nroDniPaciente, tipoDniPaciente, tipoPedido: tipoPedi }
    );
  }

  imprimirOrdenPedido(pedido, usuario) {
    return (
      this.horusRestfullUrl +
      '/api/historiaClinica/pedidos/imprimir_orden_pedido/pdf/' +
      pedido.numero +
      '/' +
      usuario +
      '/' +
      this.getRndInteger(1000000000000, 99999999999999)
    );
  }

  setHashEstudio(usuario, claveEstudios) {
    const form = {
      usuario: usuario,
      claveEstudios: claveEstudios,
    };

    return this.http.post<any>(
      this.horusRestfullUrl + '/api/administradorHashes/setHash',
      form
    );
  }

  setHashHC(userData) {
    const form = {
      usuario: userData.usuario,
      tipoDocumento: userData.tipoDocumento,
      nroDocumento: userData.nroDocumento,
      sucursal: this.sucursal,
    };

    return this.http.post<any>(
      this.horusRestfullUrl +
        '/api/administradorHashes/setHashHistorialClinico',
      form
    );
  }

  getHashEstudio(hashKey, urlHorusRestfull) {
    return this.http.post<any>(
      urlHorusRestfull +
        '/api/administradorHashes/getHashRecuperarClaveUsuario',
      hashKey
    );
  }

  getEstudio(nroEstudio) {
    // console.log(0);
    return this.http.get<any>(
      this.horusRestfullUrl +
        '/api/historiaClinica/pedidos/buscarPorNumero/' +
        nroEstudio
    );
  }
  
  getEstudioPDF(URL) { 
    // console.log(1);
    // console.log(URL);
    
    return this.http.get<any>(URL, { responseType: 'blob' as 'json'});
  }
  getInfoComplPDF(URL) {
    // console.log(2);
    // console.log(URL);

    return this.http.get<any>(URL, { responseType: 'blob' as 'json'});
  }
  
  // imprimirParaPacientes(idEstudio) { 
  //   console.log(3);

  //   return this.http.get<any>(this.horusRestfullUrl + '/api/historiaClinica/pedidos/imprimirparapacientes/pdf/' + idEstudio + '/' 
  //   + idEstudio + '/' + this.getRndInteger(1000000000000, 99999999999999));
  // } 
  

  

  guardarRegistroHCEPacientes(registroHCE, file){
    let params = new FormData();
    params.append("registrohce", registroHCE);
    params.append("informeComplementario", file);
    
    return this.http.post<any>(this.horusRestfullUrl + '/api/hcdigital/guardarRegistroHCE', params);
  }

  bajaRegistroHCEPacientes(estudio, usuario){   
    return this.http.post<any>(this.horusRestfullUrl + '/api/hcdigital/bajaRegistroHCE', 
    {
      idRegistro: estudio.idEpisodio, 
      referenciaDocumento: estudio.referenciaRegistro,
      UsuarioModifico: usuario
    });
  }

  getUrlImgAurora(estudio) {
    return this.http.post<any>(this.horusRestfullUrl + '/api/historiaClinica/pedidos/getUrlImagenAurora', estudio);
  }

  localStorageSetEstudio(estudio){
    localStorage.setItem('estudio', JSON.stringify(estudio));
  }

  localStorageGetEstudio(){
    return JSON.parse(localStorage.getItem('estudio'));
  }

  localStorageDeleteEstudio(){
    localStorage.removeItem('estudio')
  }
}
