<html>

<head>
  <meta name="robots" content="noindex">
</head>

<body>

  <div *ngIf="!cargando; else loadingTemplate" class="container-fluid">
    <div class="row d-flex justify-content-center mt-1 m-0 p-0">  
      <div class="col" *ngIf="errorEnHash">
        <h4>No se pudo cargar el estudio correctamente.</h4>
        <h5>Intente nuevamente más tarde.</h5> <br>
        <button mat-raised-button color="primary" (click)="recargarPagina()">Recargar</button>
      </div>

      <!-- <div class="col" *ngIf="hashInvalido && !errorEnHash">
        <h4>No se pudo cargar el estudio correctamente.</h4>
      </div> -->
  
      <div class="col" *ngIf="hashValido;else hashInvalidoTemplate">
        <h3>Detalle</h3>
  
        <div class="row mt-3">
          <div class="col">
            <div>
            <p>
              <b>Fecha :</b> 
              {{ selectedEstudio.fechaPedida }} 
            </p>   
            </div>     
        
            <div>
            <p>
              <b>Servicio Efector:</b>
              {{ selectedEstudio.nombreServicio | toTitleCase }}
            </p>
            </div>
          </div>
    
          <div class="col">
            <div>
            <p>
              <b>Medico Efector:</b>
              {{ selectedEstudio.actuante | toTitleCase }}
            </p>
            </div>
      
            <div *ngIf="selectedEstudio.solicitante">
              <p>
                <b>Medico Solicitante:</b>
                {{ selectedEstudio.solicitante | toTitleCase }}
              </p>
            </div>
          </div>
        </div>
  
              
  
        <div>
          <table *ngIf="selectedEstudio.nombreServicio != 'LABORATORIO'; else vihTemplate" mat-table [dataSource]="selectedEstudios" class="mat-elevation-z8">
            <ng-container matColumnDef="estudio">
                <th mat-header-cell *matHeaderCellDef>Estudio</th>
                <td mat-cell *matCellDef="let estudio">{{estudio.nombreEstudio | toTitleCase }}</td>
            </ng-container>
  
            <ng-container matColumnDef="imagen">
                <th mat-header-cell *matHeaderCellDef class="text-center">Imagen</th>
                <td mat-cell *matCellDef="let estudio" class="text-center">
                    <a *ngIf="estudio.estadoImgAurora == 0; else imgAngra" target="_blank" href="{{ estudio.urlAurora }}" mat-raised-button color="primary">Ver imagen</a>
  
                    <!-- <ng-template #imgAngra>
                        <a *ngIf="estudio.estadoImagen == 1; else noImageTemplate" target="_blank" href="{{ estudio.urlVisor }}" mat-raised-button color="primary">Ver imagen</a><br>
                    </ng-template> -->
                </td>                
  
                <ng-template #noImageTemplate>
                    <span class="text-muted">Imagen no disponible</span>
                </ng-template>
  
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table> 
        </div>
        <ng-template #vihTemplate>
          <div class="alert alert-info w-75 mx-auto my-4" role="alert" *ngIf="esEstudioVIH">
            <b>INFORMACION IMPORTANTE! </b>El resultado es entregado en sobre cerrado y bajo recibo al médico tratante (art. 8- Ley 23.798 y Decreto Reglamentario 1.244/90). En caso de resultado no reactivo, el Laboratorio podrá entregar el informe al
            paciente o representante legal, previa acreditación de identidad y vínculo (Ley 26.529, modificatorias y Decreto 1.089/12).
          </div>
        </ng-template>
  
        <div class="container-fluid">
          <div class="row" *ngIf="!esAndroid">
            <div class="col" *ngIf="existePDFEstudio && informado">
            <iframe [src]="urlEstudioPdf | safeUrl" type="application/pdf" width="100%" height="600px"></iframe> 
            </div>
  
            <!-- <div *ngIf="existePDFComp && informado">
              <iframe type="application/pdf" [src]="urlEstudioPdfComp | safeUrl" width="100%" height="600px"></iframe>
            </div>            -->
                                  
          </div>
          <div class="row" *ngIf="esAndroid">
            <div class="col">
              <a mat-raised-button *ngIf="existePDFEstudio && informado" color="primary" href="{{urlEstudioPdf}}">Descargar estudio</a>
            </div>
            <!-- <div class="col">
              <a mat-raised-button *ngIf="existePDFComp && informado" href="{{urlEstudioPdfComp}}">Descargar informe</a>
            </div> -->
          </div>
        </div>    
      </div>
      <ng-template #hashInvalidoTemplate>
        <h4>No se pudo cargar el estudio correctamente.</h4>
      </ng-template>
    </div>
  </div>
  <ng-template #loadingTemplate>
    <h6>Aguarde un momento por favor.</h6>
    <mat-progress-bar class="barra-progreso-celeste container" mode="indeterminate"></mat-progress-bar>
  </ng-template>
  
</body>

</html>

