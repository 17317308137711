import { Component, OnInit } from '@angular/core';
import { ComunicacionService } from '../_services/comunicacion.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogExpandirImagenesComponent } from '../dialog-expandir-imagenes/dialog-expandir-imagenes.component';
import { AuthenticationService } from '../_services/authentication.service';
import { UrlResolverService } from '../_services/urlResolver.service';

export interface Noticia {
  idComunicacion: number;
  nombreImagen: string;
  titulo: string;
  fechaPublicacion: string;
  texto: string;
  fechaFinPublicacion: string;
}

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css'],
})

export class CarouselComponent implements OnInit {
  files: string;
  IDAPLICACION;
  listaNoticiasTotal: Noticia[];
  noticiasCargadas: boolean = false;
  noticias: [];
  hayNoticias: boolean = false;

  constructor(
    public comunicacionService: ComunicacionService,
    public urlResolver: UrlResolverService,
    private dialog: MatDialog,
    private authService: AuthenticationService
  ) {
    this.IDAPLICACION = 32;
  }

  ngOnInit() {
    this.files = this.comunicacionService.getUrlComunicacion();
    this.getNoticiasFraccionadas();
    // this.files = this.comunicacionService.getUrlComunicacion();
    // console.log(this.files)
  }

  
  async getNoticiasFraccionadas() {
    await this.comunicacionService.getNoticiasFraccionadas(
      this.IDAPLICACION,
      0,
      10
    ).toPromise().then((res) => {
      // console.log(res)
      if (res.length !== 0) {
        this.noticias = res.elementos.filter(this.filtrarPorNull);
        //console.log(this.noticias)
        this.noticiasCargadas = true;
      } else {
        this.hayNoticias = false;
      }
    }, (error) => {
      console.log(error,"hika");
    });
  }

  filtrarPorNull(noticia: Noticia) {
    return noticia.nombreImagen != null && noticia.nombreImagen != 'null';
  }

  onItemChange($event: any): void {
    console.log('Carousel onItemChange', $event);
  }

  verImagen() {
    if (this.authService.getVersionWeb() === 'Escritorio') {
      this.dialog.open(DialogExpandirImagenesComponent, {
        data: {
          ok: false
        },
        panelClass: 'my-class'
      })
    }
  }
}
